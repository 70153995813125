@import url(~normalize.css);
@import url(~react-toastify/dist/ReactToastify.css);
@import url(~@webuildbots/wbb-chat/dist/wbb-chat.css);
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Oxygen:400,700");
@import url(~react-perfect-scrollbar/dist/css/styles.css);
@import url(~react-dates/lib/css/_datepicker.css);
@import url(~react-image-crop/dist/ReactCrop.css);
@import url(react-date-range/dist/styles.css);
@import url(react-date-range/dist/theme/default.css);
@import url(slick-carousel/slick/slick.css);
@import url(slick-carousel/slick/slick-theme.css);
@import url(sass/css/devices.min.css);
@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.pulsing {
  opacity: 1;
  transform: scale(1);
  animation: pulsing 1s infinite; }

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 1; }
  25% {
    transform: scale(0.95);
    opacity: 0.75; }
  50% {
    transform: scale(1);
    opacity: 1; } }

@keyframes flashing {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  90% {
    transform: scale(0.9); } }

@keyframes fadeOut {
  0% {
    transform: scale(1);
    opacity: 1; }
  1% {
    transform: scale(0); }
  50% {
    opacity: 0.5; }
  99% {
    opacity: 0; } }

.fade__enter {
  transition: opacity 0.2s;
  opacity: 0; }

.fade__enter-active {
  transition: opacity 0.2s;
  opacity: 1; }

.fade__exit {
  position: absolute;
  transition: opacity 0.2s;
  opacity: 1;
  left: 0;
  top: 0;
  width: 100%; }

.fade__exit-active {
  opacity: 0; }

html {
  width: 100vw; }

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-size: 14px;
  line-height: 1.25;
  background: linear-gradient(270deg, #ffffff -9.53%, rgba(255, 255, 255, 0.61) 150.57%, rgba(255, 255, 255, 0.07) 150.58%);
  word-wrap: break-word;
  color: #202a30; }
  body .plugin__wrapper {
    pointer-events: auto; }
  body .plugin__chat-toggle,
  body .plugin__chat-toggle:hover {
    opacity: 1; }
  @media (min-width: 992px) {
    body {
      font-size: 13px; } }
  @media (min-width: 1200px) {
    body {
      font-size: 14px; } }
  body * {
    box-sizing: border-box;
    outline: none !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: none; }
  @media (min-width: 992px) {
    body #wbbChatToggle {
      display: none; } }

.chat-window {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 114px;
  left: 0;
  height: auto;
  transition: opacity 0.2s;
  z-index: 0; }
  .chat-window.show {
    opacity: 1;
    z-index: 999; }
  @media (min-width: 992px) {
    .chat-window {
      opacity: 1;
      z-index: 999;
      position: relative;
      left: auto;
      top: auto; } }
  .chat-window .wbb-attachment-container {
    display: none; }

#chat-window {
  height: 548px; }
  #chat-window .wbb-container {
    height: 100%; }
  #chat-window .scroll-helper__button {
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25); }
  @media (min-width: 992px) {
    #chat-window {
      height: calc(100vh - 57px); }
      #chat-window .wbb-container {
        height: 100%; } }

#wbb-chat {
  background: #f6f6f6; }
  #wbb-chat .wbb-timestamp-container {
    margin-top: 50px; }
  #wbb-chat .wbb-timestamp {
    background: #f6f6f6; }

#wbb-input-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-color: #e9e9e9;
  z-index: 10;
  background: #ffffff; }
  @media (min-width: 992px) {
    #wbb-input-container {
      height: 70px;
      position: static; } }

.wbb-block.wbb-text-block.wbb-received {
  color: #181c28; }

.wbb-message-group.wbb-sent .wbb-messages .wbb-message-wrapper .wbb-block {
  background: linear-gradient(260.39deg, #f2f4ff 85.52%, #d1d6ea 152.21%) !important;
  border-radius: 12px !important;
  color: #818ca0 !important; }

.wbb-message-group.wbb-sent .wbb-messages .wbb-message-wrapper .wbb-block a {
  color: #181c28 !important; }

.drag-drop {
  width: 100%;
  padding-bottom: 66.66666%;
  border: 2px solid #d9d9d9;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7em;
  overflow: hidden;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s; }
  .create-an-agent .drag-drop {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    padding-bottom: 0; }
  .drag-drop--active {
    background-color: #f6f6f6;
    border-style: dashed;
    padding-bottom: 0; }
    .drag-drop--active .drag-drop__image-container {
      opacity: 0; }
  .drag-drop--react-crop {
    border: 2px solid transparent;
    height: auto;
    width: auto;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0; }
  .drag-drop:hover .text-blue--two {
    text-decoration: underline; }
  .drag-drop__icon {
    display: block;
    color: #0084ff;
    font-size: 3em; }
  .drag-drop__text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    z-index: 0;
    width: 70px;
    line-height: 1.3; }
  .drag-drop__image-container {
    width: 130px;
    height: 130px;
    position: relative;
    z-index: 1;
    transition: opacity 0.2s; }
    .create-an-agent .drag-drop__image-container {
      width: 200px;
      height: 200px; }
  .drag-drop__image {
    width: 130px;
    height: 130px;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    .create-an-agent .drag-drop__image {
      width: 200px;
      height: 200px; }
  .drag-drop .ReactCrop {
    width: auto;
    background: transparent; }
    .drag-drop .ReactCrop__image {
      width: 100%;
      height: auto;
      object-fit: contain;
      max-height: fit-content; }

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(246, 246, 246, 0.8);
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2147483641 !important; }
  .loading-spinner__image {
    width: 80px;
    margin: 0 auto;
    max-width: 80%; }
  .loading-spinner__reload {
    margin-top: 30px; }
  .loading-spinner[data-element-spinner='true'] {
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 2147483640 !important; }
    .loading-spinner[data-element-spinner='true'] .loading-spinner__image {
      position: fixed; }
